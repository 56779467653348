import {Component, Input, OnInit} from '@angular/core';
import { FolderType } from 'src/app/chat/chat.page';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
})
export class FolderComponent implements OnInit {
  @Input() folder: any;
  @Input() indentationLevel: number;

  public readonly folderType = FolderType;

  constructor() { }

  ngOnInit() {}

}
