import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeycloakIframeComponent } from './keycloak-iframe/keycloak-iframe.component';



@NgModule({
  declarations: [KeycloakIframeComponent],
  imports: [
    CommonModule
  ]
})
export class AuthModule { }
