import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatAll, filter, first, map, take, tap } from 'rxjs/operators';
import { API_BASE_URL } from '../common/global-constants';
import { BudgetCategory } from '../_models/budget-category.interface';
import { Email } from '../_models/email.interface';
import { OrderStatus } from '../_models/order-status.interface';
import { Order } from '../_models/order.interface';

@Injectable({
    providedIn: 'root'
})
export class RestService {

    constructor(private http: HttpClient) { }

    public getEmails$(): Observable<Email[]> {
        return this.http.get<Email[]>(`${API_BASE_URL}/emails/list`);
    }

    public getApprovals$(): Observable<Order[]> {
        return this.http.get<Order[]>(`${API_BASE_URL}/orders/list`, {
            params: {
                status: OrderStatus.WAITING_FOR_APPROVAL
            }
        });
    }

    public getOrders$(): Observable<Order[]> {
        return this.http.get<Order[]>(`${API_BASE_URL}/orders/list`, {});
    }

    public getOrder$(id: number): Observable<Order> {
        return this.getOrders$().pipe(map(orders => orders.filter(order => order.id === id)), concatAll(), first());
    }

    public createOrder(order: Order): Observable<Order> {
        return this.http.post<Order>(`${API_BASE_URL}/orders/create`, order);
    }

    public updateOrder(order: Order): Observable<Order> {
        const body = {
            id: order.id,
            title: order.title,
            status: order.status,
            category: order.category.id,
            sum: order.sum
        };

        return this.http.patch<Order>(`${API_BASE_URL}/orders/edit`, body);
    }

    public forwardOrderForApproval(order: Order): Observable<Order> {
        order.status = OrderStatus.WAITING_FOR_APPROVAL;
        return this.updateOrder(order);
    }

    public cancelOrderApprovalProcess(order: Order): Observable<Order> {
        order.status = OrderStatus.CREATED;
        return this.updateOrder(order);
    }

    public getBudgetCategories$(): Observable<BudgetCategory[]> {
        return this.http.get<BudgetCategory[]>(`${API_BASE_URL}/budget_categories/list`, {});
    }
}
