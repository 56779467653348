import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chats-people-searchbar',
  templateUrl: './chats-people-searchbar.component.html',
  styleUrls: ['./chats-people-searchbar.component.scss'],
})
export class ChatsPeopleSearchbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
