import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimePipe } from './pipes/date-time.pipe';

@NgModule({
  declarations: [DateTimePipe],
  exports: [DateTimePipe],
  imports: [CommonModule],
})
export class SharedModule {}
