import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserRole } from '../_models/user-role.enum';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private loggedIn$: Observable<boolean> = from(this.keycloak.isLoggedIn());

    constructor(
        private http: HttpClient,
        private jwtHelper: JwtHelperService,
        protected readonly keycloak: KeycloakService,
    ) {
    }

    public async login(): Promise<void> {
        await this.keycloak.login({
            redirectUri: window.location.origin,
        });
    }

    public logout(): void {
        this.keycloak.logout();
        this.keycloak.clearToken();
    }

    public isAuthenticated$(): Observable<boolean> {
        return this.loggedIn$;
    }

    public hasRole(role: UserRole): boolean {
        return this.keycloak.getUserRoles().includes(role);
    }

    public getUser$(): Observable<KeycloakProfile> {
        return this.isAuthenticated$().pipe(switchMap(authenticated => authenticated ? from(this.keycloak.loadUserProfile()) : of(null)));
    }
}
