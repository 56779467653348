import { ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { URLOpenListenerEvent } from '@capacitor/app/dist/esm/definitions';
import { KeycloakService } from 'keycloak-angular';
import { CLOSE_IFRAME_MESSAGE } from './common/global-constants';
import { AuthenticationService } from './_services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

    constructor(private router: Router, private zone: NgZone, private authService: AuthenticationService, private keycloak: KeycloakService) {
    }

    ngOnInit(): void {
        window.parent?.postMessage(CLOSE_IFRAME_MESSAGE, '*');

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const url = new URL(event.url);

                if (url.hostname === 'keycloak.teamkeysports.com') {
                    const navigationExtras: NavigationExtras = {
                        state: {
                            url: event.url
                        }
                    };

                    this.router.navigate(['auth'], navigationExtras);
                    return;
                }

                const slug = url.pathname.match(/[^\/]+/g);
                console.log(slug);
                if (slug) {
                    this.router.navigate(slug);
                }
                // If no match, do nothing - let regular routing
                // logic take over
            });
        });
    }
}
