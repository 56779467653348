import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(date: Date): string {
    const yesterday: Date = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));

    if (date > yesterday) {
      return date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric'});
    } else if (date <= yesterday) {
      return date.toLocaleDateString();
    }
  }

}
