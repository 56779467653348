import { Injectable } from '@angular/core';
import { interval, merge, Observable, of, Subject } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import { ChatRoom, ChatRoomMessage } from '../chat/models/chatroom';

const heno = {
    id: 1,
    username: "heno",
    name: "Henrich Vegh"
};

const johnobono = {
    id: 2,
    username: "johnobono",
    name: "Johny Pajan"
};

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private sentMessagesSubjects: Map<string, Subject<ChatRoomMessage>> = new Map();

    constructor() { }

    public getChatRooms$(): Observable<ChatRoom[]> {
        const mockData = [
            {
                id: "1",
                name: "Team A",
                createTime: new Date(),
                lastReadTimestamp: new Date(),
                lastMessage: {
                    id: "1",
                    content: "Hello, did you already replied to Johanna?",
                    author: heno,
                    createTime: new Date()
                }
            },
            {
                id: "2",
                name: "Henrich Vegh",
                createTime: new Date(),
                lastMessage: {
                    id: "1",
                    content: "Hello, when do we have a training?",
                    author: heno,
                    createTime: new Date()
                }
            }
        ]

        return of(mockData);
    }

    public getChatRoomMessages$(chatRoomId: string): Observable<ChatRoomMessage[]> {
        const dbMessages$ = this.getMessagesFromDB$(chatRoomId);

        const websocketMessage$ = interval(10000).pipe(map(() => ({
            id: new Date().getTime() + "",
            content: "new message chatroom " + chatRoomId,
            author: heno,
            createTime: new Date()
        }
        )));

        const sentMessagesSubjects$ = this.getRoomSentMessagesSubject$(chatRoomId);

        return merge(dbMessages$, websocketMessage$, sentMessagesSubjects$).pipe(
            scan((acc, curr) => {
                console.log(acc, curr);
                if (Array.isArray(curr)) {
                    acc = [...acc, ...curr];
                }
                else {
                    acc.push(curr);
                }

                return acc;
            }, []));
    }

    public sendMessage(chatRoomId: string, message: string): void {
        this.getRoomSentMessagesSubject$(chatRoomId).next({
            id: new Date().getTime() + "",
            content: message,
            author: johnobono,
            createTime: new Date()
        });
    }

    private getMessagesFromDB$(chatRoomId: string): Observable<ChatRoomMessage[]> {
        console.log(chatRoomId);
        if (chatRoomId === "1") {
            return of([
                {
                    id: "1",
                    content: "yes I will do it",
                    author: heno,
                    createTime: new Date()
                },
                {
                    id: "2",
                    content: "ok thanks",
                    author: johnobono,
                    createTime: new Date()
                },
                {
                    id: "3",
                    content: "Hello, did you already replied to Johanna?",
                    author: heno,
                    createTime: new Date()
                }
            ]);
        }

        if (chatRoomId === "2") {
            return of([
                {
                    id: "1",
                    content: "when do we have training?",
                    author: johnobono,
                    createTime: new Date()
                },
                {
                    id: "2",
                    content: "I think at 6PM",
                    author: heno,
                    createTime: new Date()
                },
                {
                    id: "3",
                    content: "oki doki, thx",
                    author: johnobono,
                    createTime: new Date()
                }
            ]);
        }
    }

    private getRoomSentMessagesSubject$(chatRoomId: string): Subject<ChatRoomMessage> {
        let subject$ = this.sentMessagesSubjects.get(chatRoomId);

        if (!subject$) {
            subject$ = new Subject();
            this.sentMessagesSubjects.set(chatRoomId, subject$);
        }

        return subject$;
    }
}
