/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChatRoom, ChatRoomMessage } from './models/chatroom';
import { of, Observable, Subject, BehaviorSubject, iif} from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { ChatService } from '../_services/chat.service';
import { takeUntil, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

enum TabModes {
    chats,
    people
}

export enum FolderType {
    group,
    user
}

@Component({
    selector: 'app-chat-page',
    templateUrl: './chat.page.html',
    styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit, OnDestroy {
    public readonly tabModes = TabModes;
    public tabMode = TabModes.chats;
    public selectedUser = {
        id: 1,
        username: "heno77",
        name: "Henrich Vegh"
    };
    public users = [
        {
            isSelected: false, // mock
            id: 2,
            name: 'Max',
            availabilityStatus: 'Online',
            lastMessage: {
                content: 'I will do it today',
                dateTime: new Date()
            },
            messages: [
                {
                    senderId: 1,
                    content: 'Hi'
                },
                {
                    senderId: 2,
                    content: 'I will do it today'
                }
            ]
        },
        {
            id: 3,
            name: 'Peter',
            availabilityStatus: 'Offline',
            lastMessage: {
                content: 'Tomorrow I will do it',
                dateTime: new Date()
            },
            messages: [
                {
                    senderId: 1,
                    content: 'Hi'
                },
                {
                    senderId: 3,
                    content: 'Tomorrow I will do it'
                }
            ]
        }
    ];

    public folders = [
        {
            title: 'Management',
            isExpanded: false,
            type: FolderType.group
        },
        {
            title: 'Teams',
            isExpanded: false,
            type: FolderType.group,
            subFolders: [
                {
                    title: 'A-Team',
                    isExpanded: false,
                    type: FolderType.group,
                    subFolders: [
                        {
                            title: 'Trainers',
                            isExpanded: false,
                            type: FolderType.group
                        },
                        {
                            title: 'Support Team',
                            isExpanded: false,
                            type: FolderType.group
                        },
                        {
                            title: 'Players',
                            isExpanded: false,
                            type: FolderType.group
                        },
                    ]
                },
            ]
        },
        { // TODO: make this a user and not a folder! once types exist
            title: 'Name Surname',
            type: FolderType.user,
            userId: 2,
        },
        {
            title: 'Name Surname',
            type: FolderType.user,
            userId: 3,
        },
    ];

    public currentUser: any = {
        id: 2,
        username: "johnobono",
        name: "Johny Pajan"
    };
    
    /////
    private unsubscribe$: Subject<void> = new Subject();
    public currentRoom$: BehaviorSubject<ChatRoom> = new BehaviorSubject(null);
    public currentRoomMessages$: Observable<ChatRoomMessage[]> = this.initCurrentChatRoomMessages();
    public rooms$: Observable<ChatRoom[]> = this.initChatRooms();
    public inputControl = new FormControl('');

    constructor(private chatService: ChatService) { }

    public ngOnInit() {

    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public setCurrentRoom(room: ChatRoom): void {
        this.currentRoom$.next(room);
    }

    public sendMessageOnEnter(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.sendMessage();
        }
    }

    public sendMessage(): void {
        if (!this.inputControl.value) {
            return;
        }

        this.currentRoom$.pipe(take(1)).subscribe(currentRoom => {
            this.chatService.sendMessage(currentRoom.id, this.inputControl.value); //TODO what if not sent?
            this.inputControl.setValue("");
        });
    }

    private initChatRooms(): Observable<ChatRoom[]> {
        return this.chatService.getChatRooms$().pipe(takeUntil(this.unsubscribe$));
    }

    private initCurrentChatRoomMessages(): Observable<ChatRoomMessage[]> {
        return this.currentRoom$.pipe(
            switchMap(chatRoom => {
                return iif(() => !!chatRoom, this.chatService.getChatRoomMessages$(chatRoom.id), of([]))
            })
        )
    }
}
