import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';
import { MainMenuService } from '../../services/main-menu.service';

@Component({
    selector: 'tmk-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit, OnDestroy {

    @HostBinding('attr.opened')
    public opened: boolean = false;

    @HostBinding('attr.show-more')
    public showMoreButton: boolean = false;

    public menuItems: any[] = [
        {
            title: 'Dashboard',
            link: '/dashboard',
            icon: 'dashboard',
        },
        {
            title: 'Users and Groups',
            link: '/organization-management/users-groups',
            icon: 'pending_actions',
            children: [
                {
                    title: 'Users',
                    link: '/organization-management/users',
                },
                {
                    title: 'Groups',
                    link: '/organization-management/groups',
                },
                // {
                //     title: 'Teams',
                //     link: '/organization-management/groups',
                // },
                // {
                //     title: 'Departments',
                //     link: '/organization-management/groups',
                // }
            ]
        },
        {
            title: 'Chat',
            link: '/chats',
            icon: 'chat',
        },
        // {
        //     title: 'Chat 1',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Chat 2',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Chat 3',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Chat 4',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Chat 5',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Chat 6',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Chat 7',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Chat 8',
        //     link: '/chats2',
        //     icon: 'chat',
        // },
        // {
        //     title: 'Approvals',
        //     link: '/approvals',
        //     icon: 'checkmark-done-circle-outline',
        //     forRoles: [UserRole.ADMIN]
        // },
        // {
        //     title: 'Orders',
        //     link: '/orders',
        //     icon: 'basket-outline',
        //     forRoles: [UserRole.USER]
        // },
        // {
        //     title: 'My Team',
        //     link: '/dashboard',
        //     icon: 'shirt-outline',
        // },
        // {
        //     title: 'Events',
        //     link: '/dashboard',
        //     icon: 'calendar-outline',
        // },
        // {
        //     title: 'Planning Board',
        //     link: '/dashboard',
        //     icon: 'cellular-outline',
        // },
        // {
        //     title: 'Equipments Manager',
        //     link: '/dashboard',
        //     icon: 'barbell-outline',
        // },
        // {
        //     title: 'Finance',
        //     link: '/dashboard',
        //     icon: 'cash-outline',
        // },
        // {
        //     title: 'Marketing',
        //     link: '/dashboard',
        //     icon: 'analytics-outline',
        // },
        // {
        //     title: 'Discussion and Help',
        //     link: '/dashboard',
        //     icon: 'help-buoy-outline',
        // },
    ];

    private unsubscribe$: Subject<void> = new Subject();

    constructor(public menuService: MainMenuService, @SkipSelf() private cd: ChangeDetectorRef) { }

    @HostListener('window:resize', ['$event'])
    public onResize(event): void {
        this.onWindowSizeChange();
    }

    public ngOnInit(): void {
        this.onWindowSizeChange();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public toggleMenu(): void {
        this.menuService.toggleMenu();
        this.opened = !this.opened;
    }

    private onWindowSizeChange(): void {
        const itemWidth = 100;
        const itemsGap = 8;

        this.showMoreButton = window.innerWidth < (this.menuItems.length + 1) * itemWidth + this.menuItems.length * itemsGap;
        //TODO: could be improved this.showMoreButton = window.innerWidth < this.menuItems.length * itemWidth + (this.menuItems.length - 1) * itemsGap;
    }

}
