import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class FinishedRegistrationGuard implements CanActivate {

    constructor(private router: Router, private keycloak: KeycloakService) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const adminRole = 'teamkey-admin';
        const userRole = 'teamkey-user';

        console.log(this.keycloak.getUserRoles());

        const finishedRegistration = this.keycloak.getUserRoles().includes(adminRole) || this.keycloak.getUserRoles().includes(userRole);
        // const finishedRegistration = true;

        if (!finishedRegistration) {
            this.router.navigate(['/complete-registration']);
        }

        return finishedRegistration;
    }

}
