import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatWindowComponent } from './components/chat-window/chat-window.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ChatPageRoutingModule } from './chat-routing.module';
import { ChatPage } from './chat.page';
import { SharedModule } from '../shared/shared.module';
import { ChatDetailActionsBarComponent } from './components/chat-detail-actions-bar/chat-detail-actions-bar.component';
import { ChatDetailProfileBarComponent } from './components/chat-detail-profile-bar/chat-detail-profile-bar.component';
import { ChatsPeopleSearchbarComponent } from './components/chats-people-searchbar/chats-people-searchbar.component';
import {
    ChatsPeopleResultsPeopleComponent
} from './components/chats-people-results-people/chats-people-results-people.component';
import { GeneralTopBarComponent } from './components/general-top-bar/general-top-bar.component';
import { FolderComponent } from '../src/app/chat/components/folder/folder.component';

@NgModule({
    declarations: [
        ChatWindowComponent,
        ChatPage,
        ChatDetailActionsBarComponent,
        ChatDetailProfileBarComponent,
        ChatsPeopleSearchbarComponent,
        ChatsPeopleResultsPeopleComponent,
        GeneralTopBarComponent,
        FolderComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        ChatPageRoutingModule,
        SharedModule
    ],
    exports: [
        ChatWindowComponent,
        ChatPage,
    ],
})
export class ChatPageModule {
}
