import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';

@Directive({
    selector: '[authenticated]'
})
export class AuthenticatedDirective {
    constructor(
        private authService: AuthenticationService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
        this.authService.isAuthenticated$().subscribe((isAuthenticated) => {
            this.onAuthStatusChange(isAuthenticated);
        });
    }

    private onAuthStatusChange(authenticated: boolean) {
        if (!authenticated) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
