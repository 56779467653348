import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MainMenuService {

    private openSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() { }

    public toggleMenu(): void {
        this.openSubject.next(!this.openSubject.getValue());
    }

    public isOpen$(): Observable<boolean> {
        return this.openSubject.asObservable();
    }
}
