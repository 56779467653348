import {Component, Input, OnInit} from '@angular/core';
import { FolderType } from '../../chat.page';

@Component({
  selector: 'app-chats-people-results-people',
  templateUrl: './chats-people-results-people.component.html',
  styleUrls: ['./chats-people-results-people.component.scss'],
})
export class ChatsPeopleResultsPeopleComponent implements OnInit {
  @Input() folders: any;
  public readonly folderType = FolderType;

  constructor() { }

  ngOnInit() {}

}
