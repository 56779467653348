import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tmk-logged-in-outlet',
  templateUrl: './logged-in-outlet.component.html',
  styleUrls: ['./logged-in-outlet.component.scss'],
})
export class LoggedInOutletComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
