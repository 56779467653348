import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRole } from '../_models/user-role.enum';
import { AuthenticationService } from '../_services/authentication.service';

@Directive({
    selector: '[forRoles]'
})
export class ForRolesDirective implements OnInit {

    @Input('forRoles') roles: UserRole[];

    constructor(private authService: AuthenticationService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    }

    ngOnInit(): void {
        let show = true;

        this.roles?.forEach(role => {
            if (!this.authService.hasRole(role)) {
                show = false;
            }
        });

        if (!show) {
            this.viewContainer.clear();
        }
        else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
