import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { DirectivesModule } from './directives/directives.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RxStompService } from './_services/rx-stomp.service';
import { myRxStompConfig } from './my-rx-stomp.config';
import { ChatPageModule } from './chat/chat.module';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakAngularModule } from 'keycloak-angular';
import { AuthModule } from './auth/auth.module';
import { TopBarComponent } from './common/components/top-bar/top-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { BottomBarComponent } from './common/components/bottom-bar/bottom-bar.component';
import { MainMenuComponent } from './common/components/main-menu/main-menu.component';
import { TranslocoRootModule } from './transloco-root.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { LoggedInOutletComponent } from './common/components/logged-in-outlet/logged-in-outlet.component';

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: 'https://keycloak.teamkeysports.com/',
                realm: 'teamkeysports-dev',
                clientId: 'teamkeysports-app',
            },

            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
                adapter: 'default'
            }
        });
}
@NgModule({
    declarations: [AppComponent, TopBarComponent, BottomBarComponent, MainMenuComponent, LoggedInOutletComponent],
    imports: [
        BrowserModule,
        CommonModule,
        MatIconModule,
        HttpClientModule,
        DirectivesModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AuthModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('ACCESS_TOKEN')
                //TODO: async retrieval is not supported at the moment
                // return new Promise(resolve => {
                //     Storage.get({key: 'ACCESS_TOKEN'}).then(res => resolve(res.value));
                // });
            },
        }),
        BrowserAnimationsModule,
        ChatPageModule,
        KeycloakAngularModule,
        TranslocoRootModule,
        NgbPopoverModule
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: RxStompService,
            useFactory: (jwtService: JwtHelperService) => {
                const rxStomp = new RxStompService();
                myRxStompConfig.connectHeaders = {
                    Authorization: jwtService.tokenGetter()
                };
                rxStomp.configure(myRxStompConfig);
                // rxStomp.activate(); //TODO
                return rxStomp;
            },
            deps: [JwtHelperService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
