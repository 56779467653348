import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-chat-detail-actions-bar',
  templateUrl: './chat-detail-actions-bar.component.html',
  styleUrls: ['./chat-detail-actions-bar.component.scss'],
})
export class ChatDetailActionsBarComponent implements OnInit {
  @Input() public user: any;
  @Output() public closeChat = new EventEmitter<void>();
  public innerWidth: number;

  public readonly console = console;
  public isMessageHighlighted = false;

  constructor() { }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  public markChatClosed() {
    console.log('AAA');
    this.closeChat.emit();
  }
}
