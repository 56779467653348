import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-detail-profile-bar',
  templateUrl: './chat-detail-profile-bar.component.html',
  styleUrls: ['./chat-detail-profile-bar.component.scss'],
})
export class ChatDetailProfileBarComponent implements OnInit {
  @Input() user: any;

  constructor() { }

  ngOnInit() {}

}
