import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { KeycloakService } from 'keycloak-angular';
import { ReplaySubject } from 'rxjs';
import { CLOSE_IFRAME_MESSAGE, KEYCLOAK_LOAD_FINISH, KEYCLOAK_UNLOAD_START } from 'src/app/common/global-constants';

@Component({
    selector: 'app-keycloak-iframe',
    templateUrl: './keycloak-iframe.component.html',
    styleUrls: ['./keycloak-iframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeycloakIframeComponent implements OnInit, OnDestroy {

    private messageListener: EventListener;

    public url: string = '';
    public iframeUrl$: ReplaySubject<SafeResourceUrl> = new ReplaySubject(1);

    constructor(private router: Router, private zone: NgZone, private sanitizer: DomSanitizer, private keycloakService: KeycloakService) { }

    public ngOnInit(): void {
        SplashScreen.show({
            autoHide: false
        });

        this.router.onSameUrlNavigation = "reload";

        this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                const url = this.router.getCurrentNavigation()?.extras?.state?.url || '';
                this.iframeUrl$.next(this.sanitizedUrl(url));
            }

        });

        const navigation = this.router.getCurrentNavigation();

        const url = navigation?.extras?.state?.url || '';
        this.iframeUrl$.next(this.sanitizedUrl(url));

        this.messageListener = async (ev: MessageEvent) => {
            switch (ev.data) {
                case CLOSE_IFRAME_MESSAGE:
                    let redirectUrl = new URL(window.location.href);
                    redirectUrl.pathname = "/";

                    SplashScreen.show({
                        autoHide: false
                    });

                    window.location.href = redirectUrl.toString();
                    break;

                case KEYCLOAK_LOAD_FINISH:
                    SplashScreen.hide();
                    break;

                case KEYCLOAK_UNLOAD_START:
                    SplashScreen.show({
                        autoHide: false
                    });
                    break;
            }
        };

        window.addEventListener("message", this.messageListener);
    }

    public ngOnDestroy(): void {
        window.removeEventListener("message", this.messageListener);
        this.router.onSameUrlNavigation = "ignore";
    }

    public sanitizedUrl(url: string): SafeResourceUrl {
        try {
            const urlObj = new URL(url);
            if (urlObj.hostname !== "keycloak.teamkeysports.com") {
                url = 'https://app.teamkeysports.com/error';
            }
        }
        catch (e) {
            url = 'https://app.teamkeysports.com/error';
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
