import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MainMenuService } from '../../services/main-menu.service';

@Component({
    selector: 'tmk-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {

    public user$ = this.authenticationService.getUser$();

    constructor(public menuService: MainMenuService, private authenticationService: AuthenticationService) { }

    public ngOnInit() { }

    public toggleMenu(): void {
        this.menuService.toggleMenu();
    }

    public async logout(): Promise<void> {
        await this.authenticationService.logout();
    }

}
