import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { map, scan, take, takeUntil } from 'rxjs/operators';
import { BudgetCategory } from 'src/app/_models/budget-category.interface';
import { Order } from 'src/app/_models/order.interface';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { RestService } from 'src/app/_services/rest.service';
import { RxStompService } from 'src/app/_services/rx-stomp.service';
import { SuggestionsTree } from '../../models/suggestions-tree';

@Component({
    selector: 'app-chat-window',
    templateUrl: './chat-window.component.html',
    styleUrls: ['./chat-window.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class ChatWindowComponent implements OnInit, OnDestroy {

    private suggestionsTree: SuggestionsTree;

    private unsubscribe$: Subject<any> = new Subject();

    private myMessages$: Subject<any> = new Subject();

    private othersMessages$: Subject<any> = new Subject();

    public chatParticipant: string;

    public typing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public messages$: Observable<any[]> = merge(this.myMessages$, this.othersMessages$).pipe(
        scan((acc, msg) => [...acc, msg], [])
    );

    private attachmentsSubject$: BehaviorSubject<any> = new BehaviorSubject(null);

    public attachments$: Observable<any[]> = this.attachmentsSubject$.pipe(
        scan((acc, att) => {
            if (Array.isArray(att)) {
                return att;
            }

            if (!att) {
                return acc;
            }

            return [...acc, att];
        }, [])
    );

    public showSuggestions$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public suggestions$: Observable<SuggestionsTree[]>;

    public suggestionPath$: BehaviorSubject<SuggestionsTree[]> = new BehaviorSubject([]);

    public suggestionsSearch$: BehaviorSubject<string> = new BehaviorSubject('');

    public messageParts$: BehaviorSubject<any[]> = new BehaviorSubject([]);

    constructor(private restService: RestService, private rxStompService: RxStompService) {

        // let budgetTree = new SuggestionsTree("Budget");
        // budgetTree.childrenProvider$ = this.restService.getBudgetCategories$()
        //     .pipe(map((categories: BudgetCategory[]) => {
        //         return categories?.map(category => SuggestionsTree.createNode(category.name, category.id, "budget_categories"))
        //     }));

        // let ordersTree = new SuggestionsTree("Orders");
        // ordersTree.childrenProvider$ = this.restService.getOrders$()
        //     .pipe(map((orders: Order[]) => {
        //         return orders?.map(order => SuggestionsTree.createNode(order.title, order.id, "approvals/detail"))
        //     }));

        // this.suggestionsTree = new SuggestionsTree("root");
        // this.suggestionsTree.childrenProvider$ = of([budgetTree, ordersTree]);
    }

    ngOnInit() {
        this.chatParticipant = 'herr@mustermann.com';

        // this.rxStompService.watch(`/user/messages/text`)
        //     .pipe(takeUntil(this.unsubscribe$))
        //     .subscribe((messageRaw: any) => {
        //         let message = JSON.parse(messageRaw.body);
        //         message.me = false;

        //         this.othersMessages$.next(message);
        //     });

        // this.rxStompService.watch(`/user/messages/typing`)
        //     .pipe(takeUntil(this.unsubscribe$))
        //     .subscribe((typing: any) => {
        //         this.typing$.next(typing.body === "true");
        //     });

        //     this.showSuggestions$
        //         .pipe(takeUntil(this.unsubscribe$))
        //         .subscribe(next => {
        //             if (next) {
        //                 this.suggestions$ = this.suggestionsTree.getChildren$('');
        //             }
        //     });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
    }

    toggleSuggestions():  void {
        this.showSuggestions$.next(!this.showSuggestions$.getValue());
    }

    onChatTyping(ev: KeyboardEvent) {
        const target: any = ev.target;
        const text = target.innerText;

        if (ev.code === 'Enter') {
            this.attachments$
                .pipe(take(1))
                .subscribe(attachments => {
                    const message = {
                        me: true,
                        text,
                        attachments
                    };

                    this.myMessages$.next(message);
                    // this.rxStompService.publish({
                    //     destination: '/stomp/message/text',
                    //     body: JSON.stringify(message)
                    // });
                });



            // this.rxStompService.publish({
            //     destination: '/stomp/message/typing',
            //     body: "0"
            // });

            target.innerText = '';
            this.showSuggestions$.next(false);
            this.attachmentsSubject$.next([]);
        }
        else {
            // this.rxStompService.publish({
            //     destination: '/stomp/message/typing',
            //     body: text === "" ? "0" : "1"
            // });
        }

    }

    public onSuggestionClick(suggestion: SuggestionsTree): void {
        console.log(suggestion);

        this.suggestionPath$.next([...this.suggestionPath$.getValue(), suggestion]);

        if (suggestion.isNode()) {
            this.attachmentsSubject$.next({
                name: suggestion.name,
                id: suggestion.id,
                type: suggestion.type
            });

            this.showSuggestions$.next(false);
        }
        else {
            this.suggestions$ = suggestion.getChildren$();
        }
    }
}
